import React from 'react';
import { VisuallyHidden } from '../visually-hidden';
import { ButtonNext, ButtonPrev, BackArrow, ForwardArrow } from './image-banner.styles';

type NavigationButtonProps = {
  enabled: boolean;
  onClick: (event: React.MouseEvent) => void;
};

export const PrevButton = ({ enabled, onClick }: NavigationButtonProps): JSX.Element => (
  <ButtonPrev className='--left' disabled={!enabled} onClick={onClick}>
    <VisuallyHidden>Previous Slide</VisuallyHidden>
    <BackArrow onClick={onClick} height={10} width={17} />
  </ButtonPrev>
);

export const NextButton = ({ enabled, onClick }: NavigationButtonProps): JSX.Element => (
  <ButtonNext className='--right' disabled={!enabled} onClick={onClick}>
    <VisuallyHidden>Next Slide</VisuallyHidden>
    <ForwardArrow onClick={onClick} height={10} width={17} />
  </ButtonNext>
);
