import React, { ReactNode, RefObject, useImperativeHandle, useRef } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { CategoriesMobileDisplayOption } from 'types/graphql';
import { useIntersectionObserver } from 'src/hooks/use-intersection-observer';
import { VisuallyHidden } from 'src/components/core/visually-hidden';
import { BackArrow, ForwardArrow } from 'src/components/carousel-slider/carousel-buttons';
import {
  ButtonNext,
  ButtonPrev,
  GradientLeft,
  GradientRight,
  Grid,
  Heading,
  Items,
  ItemsContainer,
  SliderContainer,
  ViewAllButton,
  Viewport,
  WatcherRight,
  Wrapper,
  WrapperShift,
} from './list.styles';

type ListProps = {
  children: ReactNode;
  mobileDisplay: CategoriesMobileDisplayOption;
  enableViewAll?: boolean;
  isExpanded?: boolean;
  viewAllItems?: () => void;
  showCategoriesBorder: boolean;
  alwaysUseMobileGap?: boolean;
};

type SliderRef = {
  viewportRef: RefObject<HTMLDivElement>;
  itemsWrapperRef: RefObject<HTMLDivElement>;
};

export const List = ({
  children,
  mobileDisplay,
  enableViewAll = true,
  isExpanded,
  viewAllItems,
  showCategoriesBorder,
  alwaysUseMobileGap = false,
}: ListProps): JSX.Element => {
  const flags = useFlags();
  const isProductCardsV2Enabled = flags['growth.ecomm.product-card-v2.rollout'] ?? false;

  const sliderRef = useRef<SliderRef>(null);
  const viewportRef = useRef<HTMLDivElement>(null);
  const itemsWrapperRef = useRef<HTMLDivElement>(null);
  const startWatcherRef = useRef<HTMLDivElement>(null);
  const startWatcher = useIntersectionObserver(startWatcherRef);
  const endWatcherRef = useRef<HTMLDivElement>(null);
  const endWatcher = useIntersectionObserver(endWatcherRef);
  const isMobileGrid = mobileDisplay === CategoriesMobileDisplayOption.grid;

  useImperativeHandle(
    sliderRef,
    () => ({
      viewportRef,
      itemsWrapperRef,
    }),
    []
  );

  const onButtonClick = (direction = 1): void => {
    if (!viewportRef.current || !itemsWrapperRef.current) {
      return;
    }

    const { clientWidth: containerWidth, scrollLeft: containerLeft } = viewportRef.current;

    const elements = [...itemsWrapperRef.current.children].filter((child) => child.childNodes.length > 0);

    let target;
    let scrollToLeft = 0;
    const buffer = 20;

    if (direction === 1) {
      target = elements.find((el: HTMLElement) => {
        const { offsetLeft, clientWidth } = el;
        return Number(offsetLeft) + Number(clientWidth) - containerLeft > containerWidth;
      });

      if (!target) {
        return;
      }

      scrollToLeft = target.offsetLeft - buffer;
    } else if (direction === -1) {
      target = elements.reverse().find((el: HTMLElement) => {
        const { offsetLeft } = el;
        return offsetLeft < containerLeft;
      });

      if (!target) {
        return;
      }

      scrollToLeft = Number(target.offsetLeft) + Number(target.clientWidth) - containerWidth - buffer;
    }

    viewportRef.current.scrollTo({ left: scrollToLeft, behavior: 'smooth' });
  };

  return (
    <Wrapper $showCategoriesBorder={showCategoriesBorder} $isStandardMaxWidth={isProductCardsV2Enabled}>
      <WrapperShift>
        <Heading tag='h2' size='medium'>
          Categories
        </Heading>

        {isMobileGrid && (
          <Grid ref={itemsWrapperRef} $alwaysUseMobileGap={alwaysUseMobileGap}>
            {children}
          </Grid>
        )}

        {enableViewAll && isMobileGrid && !isExpanded && <ViewAllButton onClick={viewAllItems}>View All</ViewAllButton>}

        {/* TODO this should be refactored to use carousel-v2, create a new type for categories */}
        <SliderContainer ref={sliderRef} $isMobileGrid={isMobileGrid}>
          <Viewport ref={viewportRef}>
            <GradientLeft isVisible={!startWatcher?.isIntersecting} />

            <ItemsContainer>
              <div ref={startWatcherRef} />

              <Items ref={itemsWrapperRef} $alwaysUseMobileGap={alwaysUseMobileGap}>
                {children}
              </Items>

              <WatcherRight ref={endWatcherRef} />
            </ItemsContainer>

            <GradientRight isVisible={!endWatcher?.isIntersecting} />
          </Viewport>

          <ButtonPrev
            onClick={() => onButtonClick(-1)}
            disabled={startWatcher?.isIntersecting}
            $isPositionNormalized={isProductCardsV2Enabled}
          >
            <VisuallyHidden>Previous</VisuallyHidden>
            <BackArrow />
          </ButtonPrev>

          <ButtonNext
            onClick={() => onButtonClick(1)}
            disabled={endWatcher?.isIntersecting}
            $isPositionNormalized={isProductCardsV2Enabled}
          >
            <VisuallyHidden>Next</VisuallyHidden>
            <ForwardArrow />
          </ButtonNext>
        </SliderContainer>
      </WrapperShift>
    </Wrapper>
  );
};
